import React, { useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import cx from "classnames";
import Slider from "react-slick";
import { NextSeo } from "next-seo";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useEffectOnce } from "usehooks-ts";
import { appUrls, siteUrls } from "utils";

import One from "assets/img/home/num1.svg";
import Two from "assets/img/home/num2.svg";
import Three from "assets/img/home/num3.svg";
import Footer from "components/Footer";
import Header from "components/Header";
import LogoShowcase, { Logo } from "components/LogoShowcase";
import Random from "components/Random";
import Terminal from "components/Terminal";

// eslint-disable-next-line import/no-named-as-default
import ErrorBoundary from "components/ErrorBoundary";
import ErrorReporter from "components/ErrorReporter";
import Link from "components/Link";
import Notifications from "components/Notifications";
import withAuthData from "utils/withAuthData";
import "utils/parallax-scroll";
import wow from "utils/wow";


const useStyles = makeStyles((theme) => ({
    // Apps
    appsBgCircle: {
        position: "absolute",
        top: "-20%",
        bottom: "auto",
        zIndex: 0,
    },
    appsSection: {
        paddingTop: "40px",
        paddingBottom: "40px",
        "& .editor-content": {
            paddingLeft: 0,
        },
        "@media (max-width: 575px)": {
            paddingTop: "60px",
        },
    },
    appsShowcaseCol: {
        display: "flex",
        alignItems: "center",
    },
    // Header
    header: {
        "@media (max-width: 991px)": {
            "& .header-transparent .adaptable-lockup_svg__cls-2": {
                fill: "#fff",
            },
        },
    },

    // Hero
    cloud1: {
        position: "absolute",
        left: 0,
        top: 0,
        maxWidth: "22%",
        animation: "moveclouds 12s 0s linear infinite alternate",
    },
    cloud2: {
        position: "absolute",
        left: "85%",
        top: "5%",
        maxWidth: "14%",
        animation: "moveclouds 8s 0.3s linear infinite alternate",
    },
    heroButtons: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        "& a": {
            margin: "6px",
        },
    },
    heroSlideItem: {
        height: "1.2em",
        "@media (max-width: 400px)": {
            height: "2.5em",
        },
    },
    heroSmallText: {
        fontSize: "14px",
        paddingTop: "11px",
        textAlign: "center",
        width: "100%",
        "@media (max-width: 991px)": {
            color: "rgba(255, 255, 255, 0.8)",
        },
    },

    featureImg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "600px",
        maxWidth: "600px",
        "@media (max-width: 991px)": {
            maxHeight: "450px",
            maxWidth: "450px",
        },
    },

    // BatteriesIncluded
    batteries: {
        width: "85%",
        "@media (max-width: 991px)": {
            width: "60%",
        },
    },
    featureList: {
        paddingLeft: "28px",
        paddingTop: "28px",
        "& li": {
            fontSize: "18px",
        },
    },

    // DeployAutomagically
    wizard: {
        paddingTop: "50px",
        "@media (max-width: 991px)": {
            width: "60%",
        },
        "@media (max-width: 768px)": {
            width: "85%",
        },
    },

    // EffortlessDevOps
    devopsBgCircle: {
        position: "absolute",
        top: "-20%",
        bottom: "auto",
        zIndex: 0,
    },

    // HowItWorks
    howCode: {
        fontSize: "1rem",
    },
    howContainer: {
        paddingBottom: "100px",
        "@media (max-width: 991px)": {
            paddingBottom: "72px",
        },
    },
    howDeploy: {
        marginTop: "2rem",
        position: "relative",
    },
    howImg: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
    },
    howNumber: {
        width: "33%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2rem",
        maxWidth: "180px",
        "& .num1_svg__cls-1,.num2_svg__cls-1,.num3_svg__cls-1": {
            fill: theme.palette.primary.main,
        },
    },
    howPointer: {
        position: "absolute",
        width: "39px",
        left: "70%",
        top: "63%",

    },
    howText: {
        marginTop: "2rem",
        fontSize: "1.1rem",
        paddingLeft: "4%",
        paddingRight: "4%",
        "@media (max-width: 991px)": {
            marginBottom: "4rem",
        },
    },
    howTextLink: {
        textDecoration: "underline",
        fontSize: "1.1rem",
        background: "none",
        border: "none",
    },
    howTitle: {
        fontSize: "1.5rem",
        fontWeight: 600,
        lineHeight: 1.5,
        marginBottom: "2rem",
        minHeight: "4.5rem",
        "@media (max-width: 991px)": {
            minHeight: "unset",
        },
    },
    screenshot: {
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 13px -6px, rgba(0, 0, 0, 0.14) 0px 20px 31px 3px, rgba(0, 0, 0, 0.12) 0px 8px 38px 7px",
    },

    // CallToAction
    ctaSmallText: {
        color: "rgba(255, 255, 255, 0.80)",
        fontSize: ".9rem",
    },
    ctaButtonBox: {
        "@media (max-width: 768px)": {
            marginTop: "2rem",
        },
    },
}));
type HomeStyles = ReturnType<typeof useStyles>;

const sliderSettings = {
    accessibility: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    draggable: false,
    pauseOnHover: false,
    speed: 300,
    swipe: false,
    touchMove: false,
    vertical: true,
};

function Hero({ classes }: { classes: HomeStyles }) {
    return (
        <section className="banner banner-two">
            <div className="vector-bg"><img src="media/banner/top shape.png" alt="" /></div>
            <div className="container">
                <div className="banner-content-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="banner-content">
                                <h1 className="banner-title">
                                    The easiest way to deploy and scale your
                                    {" "}
                                    <Slider {...sliderSettings}>
                                        <span className={classes.heroSlideItem}>
                                            Full Stack App
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Node.js App
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Golang App
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Express App
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Laravel App
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Prisma App
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Backend API
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Python App
                                        </span>
                                        <span className={classes.heroSlideItem}>
                                            Feathers.js API
                                        </span>
                                    </Slider>
                                </h1>

                                <p className="description">
                                    Just connect your GitHub repository and
                                    let Adaptable handle the rest.
                                </p>


                                <div className={classes.heroButtons}>
                                    <Link href={appUrls.deployDemo} className="pix-btn banner-btn color-two" noLinkStyle>Deploy&nbsp;a&nbsp;Starter&nbsp;App</Link>
                                    <Link href={appUrls.connectRepo} className="pix-btn color-two-outline banner-btn" noLinkStyle>Deploy&nbsp;Your&nbsp;Own&nbsp;Repo</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <Random>
                                <img src="/img/home/guy-on-cloud-1.svg" alt="" />
                                <img src="/img/home/guy-on-cloud-2.svg" alt="" />
                            </Random>
                            <img src="/img/home/cloud1.svg" alt="" className={classes.cloud1} />
                            <img src="/img/home/cloud1.svg" alt="" className={classes.cloud2} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function BatteriesIncluded({ classes }: { classes: HomeStyles }) {
    return (
        <section className="editor-design-two batteries">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <img src="img/home/batteries.svg" alt="batteries" className={cx("wow pixFade", classes.featureImg, classes.batteries)} />
                    </div>
                    <div className="col-lg-6">
                        <div className="editor-content color-two">
                            <div className="section-title style-two color-two">
                                <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                                    Batteries Included
                                </h2>

                                <p className="wow pixFadeUp" data-wow-delay="0.5s">
                                    Everything you need, right out of the box
                                </p>
                            </div>

                            <div className="section-title style-two description wow pixFadeUp" data-wow-delay="0.7s">
                                <p>
                                    Adaptable&apos;s powerful deployment templates
                                    provision
                                    {" "}
                                    <strong>all</strong>
                                    {" "}
                                    of the full-stack resources needed to run
                                    your app in production:
                                </p>
                                <ul className={cx("list-items color-two", classes.featureList)}>
                                    <li>Autoscaling, Serverless, Containers</li>
                                    <li>Fully-managed, Scalable Database</li>
                                    <li>Load Balancing</li>
                                    <li>Fully-managed HTTPS (SSL/TLS)</li>
                                    <li>and more...</li>
                                </ul>
                            </div>

                            <Link href={siteUrls.whatIsAdaptable} className="pix-btn color-two wow pixFadeUp" data-wow-delay="0.9s" noLinkStyle>Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function DeployAutomagically({ classes }: { classes: HomeStyles }) {
    return (
        <section className="genera-informes-two">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pix-order-two">
                        <div className="genera-informes-content">
                            <div className="section-title style-two">
                                <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                                    Deploy Automagically
                                </h2>

                                <div className="description wow pixFadeUp" data-wow-delay="0.7s">
                                    <p>
                                        Adaptable monitors your connected GitHub
                                        branch and automatically updates your deployment
                                        when you push.
                                    </p>
                                    <p>
                                        Connect one branch for production and another for
                                        staging.
                                        Use dev branches to create developer
                                        previews.
                                    </p>
                                </div>
                            </div>


                            <Grid container spacing={2} className="wow pixFadeUp" data-wow-delay="0.9s">
                                <Grid item>
                                    <Link href={appUrls.deployDemo} className="pix-btn color-two" noLinkStyle>Deploy&nbsp;a&nbsp;Starter&nbsp;App</Link>
                                </Grid>
                                <Grid item>
                                    <Link href={appUrls.connectRepo} className="pix-btn color-two btn-outline-two" noLinkStyle>Deploy&nbsp;Your&nbsp;Own&nbsp;Repo</Link>
                                </Grid>
                            </Grid>
                        </div>


                    </div>

                    <div className="col-lg-6">
                        <img src="img/home/wizard.svg" alt="wizard" className={cx("wow pixFade", classes.featureImg, classes.wizard)} />
                    </div>

                </div>
            </div>

        </section>

    );
}

function EffortlessDevOps({ classes }: { classes: HomeStyles }) {
    return (
        <section className="editor-design-two">
            <div className={cx("wow pixFadeRight", classes.devopsBgCircle)}>
                <img src="media/background/circle.png" data-parallax='{"y" : -230}' alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <img src="/img/home/yoga-pose.svg" alt="smiling woman doing yoga" className={cx("wow pixFade", classes.featureImg)} />
                    </div>
                    <div className="col-lg-6">
                        <div className="editor-content color-two">
                            <div className="section-title style-two color-two">
                                <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                                    Effortless DevOps
                                </h2>

                                <p className="wow pixFadeUp" data-wow-delay="0.5s">
                                    Without the DevOps Team
                                </p>
                            </div>

                            <div className="section-title style-two description wow pixFadeUp" data-wow-delay="0.7s">
                                <p>
                                    Get all the benefits of an advanced
                                    continuous deployment system without having
                                    to deal with Kubernetes, Docker, or
                                    stitching together complex cloud resources.
                                </p>
                                <p>
                                    Adaptable&apos;s app templates use state of
                                    the art infrastructure and tools under the
                                    hood so you can focus on developing your
                                    app.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const HowText1 = ({ classes, className }: { classes: HomeStyles, className?: string }) => (
    <div className={cx(classes.howText, className)}>
        Choose a repository and branch to be
        deployed.
        <br />
        Pick a development branch to
        create a preview deployment.
    </div>
);

const HowText2 = ({
    classes,
    className,
    tryItClicked,
}: {
    classes: HomeStyles,
    className?: string,
    tryItClicked?: () => void
}) => (
    <div className={cx(classes.howText, className)}>
        App templates take care of building your
        app and defining resources like serverless
        containers, databases, and load balancing.
        {" "}
        <button className={cx(classes.howTextLink, "body-text")} type="button" onClick={() => { if (tryItClicked) tryItClicked(); }}>Give it a try!</button>
    </div>
);

const HowText3 = ({ classes, className }: { classes: HomeStyles, className?: string }) => (
    <div className={cx(classes.howText, className)}>
        Each time you push to GitHub, Adaptable builds your latest code
        and updates your deployment.
    </div>
);

type NewAppDialogState = "open" | "open-unsure" | "closed";
export interface NewAppDialogProps {
    classes: HomeStyles;
    state: NewAppDialogState;
    onClose: () => void;
    unsure?: boolean
}
export const NewAppDialog = ({
    classes, state, onClose,
}: NewAppDialogProps) => (
    <Dialog open={state !== "closed"} onClose={onClose} aria-labelledby="new-app-dialog-title">
        <DialogTitle style={{ padding: "20px 50px 20px 50px" }} id="new-app-dialog-title">
            {state === "open-unsure" ? "Deploy an App on Adaptable?" : "Deploy an App on Adaptable"}
        </DialogTitle>
        <DialogContent style={{ padding: "0px 50px 20px 50px" }}>
            { state === "open-unsure"
                ? (
                    <DialogContentText>
                        It looks like you are trying to deploy an application using Adaptable.
                        You can either deploy a demo application using a starter, which you can
                        customize later, or you can deploy an existing repository from GitHub.
                    </DialogContentText>

                )
                : (
                    <DialogContentText>
                        You can deploy a demo application using a starter, which you
                        can later customize, or you can deploy an existing repository from
                        GitHub.
                    </DialogContentText>
                ) }
            <div className={cx(classes.heroButtons)} style={{ paddingTop: "20px" }}>
                <Link href={appUrls.deployDemo} className="pix-btn banner-btn color-two" noLinkStyle>Deploy&nbsp;a&nbsp;Starter&nbsp;App</Link>
                <Link href={appUrls.connectRepo} className="pix-btn color-two btn-outline-two banner-btn" noLinkStyle>Deploy&nbsp;Your&nbsp;Own&nbsp;Repo</Link>
            </div>
            <div style={{
                display: "inline-block",
                marginTop: "10px",
                textAlign: "right",
                width: "100%",
            }}
            >
                <Button onClick={onClose}>Cancel</Button>
            </div>
        </DialogContent>
    </Dialog>
);

function HowItWorks({ classes }: { classes: HomeStyles }) {
    const [deployAnAppState, setDeployAnAppOpen] = useState<NewAppDialogState>("closed");
    return (
        <section className={cx("featured-two-same first-section", classes.howContainer)}>
            <div className="container">
                <div className="section-title color-two text-center">
                    <h3 className="sub-title wow pixFadeUp">How Adaptable Works</h3>
                    <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">Easy as 1, 2, 3</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 text-center">
                        <div className="wow pixFadeRight text-center" data-wow-delay="0.5s">
                            <One className={classes.howNumber} />
                            <div>
                                <h3 className={classes.howTitle}>Connect Your GitHub Repo</h3>
                                <img src="/img/home/connectrepo.png" alt="connect repo screenshot" className={cx(classes.screenshot, classes.howImg)} />
                                <HowText1 classes={classes} className="d-lg-none" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center">
                        <div className="wow pixFadeRight text-center" data-wow-delay="0.6s">
                            <Two className={classes.howNumber} />
                            <div>
                                <h3 className={classes.howTitle}>
                                    Choose a Template
                                    <br />
                                    and Deploy
                                </h3>
                                <img src="/img/home/choosetemplate.png" alt="choose a template screenshot" className={cx(classes.screenshot, classes.howImg)} />
                                <Button
                                    onClick={() => { setDeployAnAppOpen("open-unsure"); }}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={cx(classes.howDeploy, classes.screenshot)}
                                >
                                    Deploy
                                    <img src="/img/home/pointer.png" alt="" className={classes.howPointer} />
                                </Button>
                                <HowText2 tryItClicked={() => { setDeployAnAppOpen("open"); }} classes={classes} className="d-lg-none" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center">
                        <div className="wow pixFadeRight text-center" data-wow-delay="0.7s">
                            <Three className={classes.howNumber} />
                            <div>
                                <h3 className={classes.howTitle}>Just Push to Update</h3>
                                <Terminal title="Laptop">
                                    <pre className={classes.howCode}>{`\n > git push\n`}</pre>
                                </Terminal>
                                <HowText3 classes={classes} className="d-lg-none" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-none d-lg-block">
                    <div className="row">
                        <div className="col-lg-4 text-center">
                            <div className="wow pixFadeRight text-center" data-wow-delay="0.5s">
                                <HowText1 classes={classes} />
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                            <div className="wow pixFadeRight text-center" data-wow-delay="0.6s">
                                <HowText2
                                    tryItClicked={() => { setDeployAnAppOpen("open"); }}
                                    classes={classes}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                            <div className="wow pixFadeRight text-center" data-wow-delay="0.7s">
                                <HowText3 classes={classes} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NewAppDialog
                classes={classes}
                state={deployAnAppState}
                onClose={() => { setDeployAnAppOpen("closed"); }}
            />
        </section>
    );
}

const logoPath = "/img/tech_logos";
const logos: Logo[] = [
    {
        title: "JavaScript",
        file: "javascript.svg",
    },
    {
        title: "Go",
        file: "go.svg",
    },
    {
        title: "Node.js",
        file: "nodejs-logo-hex.svg",
    },
    {
        title: "Feathers.js",
        file: "feathers-logo-circle-black.png",
    },
    {
        title: "React",
        file: "react.svg",
    },
    {
        title: "Laravel",
        file: "laravel.svg",
    },
    {
        title: "Prisma",
        file: "prisma.svg",
    },
    {
        title: "TypeScript",
        file: "typescript.svg",
    },
    {
        title: "PHP",
        file: "php.svg",
    },
    {
        title: "Express",
        file: "express-logo-circle.png",
    },
    {
        title: "Python",
        file: "python.svg",
    },
    {
        title: "NestJS",
        file: "nestjs.svg",
    },
    {
        title: "MongoDB",
        file: "mongodb.svg",
    },
];

function Apps({ classes }: { classes: HomeStyles }) {
    const { appsBgCircle, appsSection, appsShowcaseCol } = classes;

    return (
        <section className={appsSection}>
            <div className={cx("wow pixFadeRight d-none d-lg-block", appsBgCircle)}>
                <img src="media/background/circle.png" data-parallax='{"y" : -230}' alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pix-order-two">
                        <div className="editor-content">
                            <div className="section-title style-two color-two">
                                <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                                    What apps can I run on Adaptable?
                                </h2>
                                <p className="wow pixFadeUp" data-wow-delay="0.5s">
                                    Back end, front end, and full stack web apps
                                </p>
                            </div>

                            <div className="section-title style-two description wow pixFadeUp" data-wow-delay="0.7s">
                                <p>
                                    Adaptable is designed to host almost any
                                    web-based app, along with all of the
                                    production-ready services your app needs.
                                    Whether you&apos;re building an API, a web UI
                                    or a full stack app,
                                    {" "}
                                    <strong>you</strong>
                                    {" "}
                                    choose the tech stack and leave the hosting
                                    and deployment to us.
                                </p>
                                <p>
                                    Here are just a few of the technologies
                                    that work well with Adaptable:
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={cx("col-lg-6", appsShowcaseCol)}>
                        <LogoShowcase logos={logos} pathPrefix={logoPath} />
                    </div>
                </div>
                <div className="row justify-content-center wow pixFadeUp" data-wow-delay="0.9s">
                    <div className="col-lg-3 col-sm-4 col-6">
                        <h5>Languages</h5>
                        <ul>
                            <li>Go</li>
                            <li>JavaScript</li>
                            <li>Python</li>
                            <li>PHP</li>
                            <li>TypeScript</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-6">
                        <h5>Frameworks &amp; Libraries</h5>
                        <ul>
                            <li>Feathers</li>
                            <li>Gin</li>
                            <li>Laravel</li>
                            <li>NestJS</li>
                            <li>Prisma</li>
                            <li>React</li>
                            <li>...and more!</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-7">
                        <h5>Databases</h5>
                        <ul>
                            <li>MongoDB</li>
                            <li>PostgreSQL</li>
                            <li>Microsoft SQL Server</li>
                        </ul>
                    </div>
                </div>
                <Grid container spacing={2} className="wow pixFadeUp mt-3" data-wow-delay="1.1s" direction="row" justifyContent="center" alignItems="center">
                    <Grid item>
                        <Link
                            href={`${appUrls.fromStarter}?starter=feathers-chat`}
                            style={{ whiteSpace: "nowrap" }}
                            className="pix-btn color-two"
                            noLinkStyle
                        >
                            Deploy a Feathers.js Starter App
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            href={`${appUrls.fromStarter}?starter=express-prisma-mongo`}
                            style={{ whiteSpace: "nowrap" }}
                            className="pix-btn color-two"
                            noLinkStyle
                        >
                            Deploy a Prisma Starter App
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}


function CallToAction({ classes }: { classes: HomeStyles }) {
    return (
        <section className="signup-section">
            <div className="bg-shape">
                <img src="media/background/left-shape.png" data-parallax='{"x": -130}' alt="shape" className="shape-left" />
                <img src="media/background/right_shape.png" data-parallax='{"x": 130}' alt="shape" className="shape-right" />
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="signup-heading wow pixFadeUp">
                            <h2 className="title">
                                Take a Test Drive
                            </h2>

                            <p>
                                Deploy a demo Feathers.js chat app, complete
                                with autoscaling compute, MongoDB database,
                                and local auth.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <Box className={`wow pixFadeUp ${classes.ctaButtonBox}`} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Link href={appUrls.deployDemo} className="pix-btn btn-large btn-light color-two" noLinkStyle>Deploy&nbsp;a&nbsp;Starter&nbsp;App</Link>
                        </Box>
                    </div>
                </div>
            </div>
        </section>
    );
}

export function Home() {
    const classes = useStyles();
    const title = "The Easiest Way to Deploy Your App";
    const description = "Connect your GitHub repository or choose a starter application and deploy. See your app live in minutes.";
    const ogDescription = "Connect your GitHub repo or choose a starter and deploy. See your app live in minutes.";

    useEffectOnce(wow);

    return (
        <div id="main_content">
            <NextSeo
                title={title}
                description={description}
                openGraph={{
                    title,
                    description: ogDescription,
                }}
            />
            <ErrorBoundary>
                <Notifications>
                    <ErrorReporter />
                    <Header transparent className={classes.header} noOrg />
                    <ErrorBoundary>
                        <Hero classes={classes} />
                        <HowItWorks classes={classes} />
                        <Apps classes={classes} />
                        <BatteriesIncluded classes={classes} />
                        <DeployAutomagically classes={classes} />
                        <EffortlessDevOps classes={classes} />
                        <CallToAction classes={classes} />
                    </ErrorBoundary>
                    <Footer />
                </Notifications>
            </ErrorBoundary>
        </div>
    );
}

export default withAuthData(Home);
