import React, {
    Children, ReactNode, useEffect, useState,
} from "react";

export interface RandomProps {
    children: ReactNode | ReactNode[];
    /**
     * Always render the first child for SSR, then randomly choose a child
     * on the client after rehydration. This can cause a flash of
     * content when the child is changed.
     */
    renderSSR?: boolean;
}

/**
 * Randomly choose one of a set of components to display.
 *
 * By default, no child is rendered for SSR. A child is chosen after
 * rehydration. To always render a child for SSR, see the renderSSR prop.
 */
export default function Random({ children, renderSSR }: RandomProps) {
    const kids = Children.toArray(children);
    const nKids = kids.length;
    const [which, setWhich] = useState(renderSSR ? 0 : undefined);

    useEffect(() => {
        setWhich(Math.floor(Math.random() * nKids));
    }, [nKids]);

    if (which === undefined || nKids === 0) return null;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (<>{kids[which]}</>);
}
