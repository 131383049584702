/* eslint-disable global-require */
/* eslint-disable import/no-webpack-loader-syntax */

export default function init() {
    // wow expects `this` to be `window`, so tell webpack/imports-loader to do that
    // eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
    require("imports-loader?wrapper=window!assets/dependencies/wow/js/wow.min");

    // eslint-disable-next-line no-undef
    const wow = new WOW({ mobile: false });
    wow.init();
}
