import React, { ReactNode } from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        borderRadius: "7px",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 13px -6px, rgba(0, 0, 0, 0.14) 0px 20px 31px 3px, rgba(0, 0, 0, 0.12) 0px 8px 38px 7px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginTop: "2em",
        marginBottom: "2em",
        textAlign: "left",
        // width: "fit-content",
        width: "100%",
    },
    header: {
        display: "flex",
        padding: "5px",
        background: "linear-gradient(#3b3b3b, #212121)",
    },
    button: {
        width: "15px",
        height: "12px",
        marginRight: "8px",
        borderRadius: "50%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        padding: "0",
    },
    close: {
        background: "#ff615a",
    },
    minimize: {
        background: "#ffc230",
    },
    expand: {
        background: "#29cb41",
    },
    title: {
        textAlign: "center",
        color: "#b7b6b7",
        fontFamily: "Roboto, sans-serif",
        fontSize: "0.9rem",
        fontWeight: 600,
        width: "100%",
        paddingRight: "60px",
        height: "28px",
    },
    content: {
        padding: "10px",
        fontSize: "14px",
        color: "lightgray",
        lineHeight: "1.5",
    },
});

export interface TerminalProps {
    children: ReactNode | ReactNode[];
    title?: string;
}

export default function Terminal({ children, title }: TerminalProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={`${classes.button} ${classes.close}`} />
                <div className={`${classes.button} ${classes.minimize}`} />
                <div className={`${classes.button} ${classes.expand}`} />
                <div className={classes.title}>
                    <span>{title || ""}</span>
                </div>
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </div>

    );
}
