import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React from "react";
import Slider from "react-slick";

const sliderSettings = {
    autoplay: true,
    accessibility: false,
    arrows: false,
    draggable: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToShow: 3,
    speed: 2000,
    touchMove: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    respondTo: "slider",
    variableWidth: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};
const useStyles = makeStyles({
    showcase: {
        overflow: "hidden",
        width: "100%",
        "& .slick-track": {
            display: "flex",
            alignItems: "center",
        },
    },
    slide: {
        padding: "0 20px",
        position: "relative",
        minWidth: "200px",
        "& img": {
            maxWidth: "none",
            height: "200px",
            display: "block",
            margin: "auto",
            "@media (max-width: 500px)": {
                height: "150px",
            },
        },
        "& p": {
            marginTop: "10px",
            textAlign: "center",
            marginBottom: "0",
        },
    },
    wrapper: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        width: "100%",
    },
});

export interface Logo {
    title: string;
    file: string;
    soon?: boolean;
}

export interface LogoShowcaseProps {
    logos: Logo[];
    pathPrefix?: string;
}

const LogoShowcase = ({ logos, pathPrefix = "" }: LogoShowcaseProps) => {
    const { showcase, slide, wrapper } = useStyles();
    if (pathPrefix) pathPrefix += "/";

    return (
        <div className={wrapper}>
            <div className={showcase}>
                <Slider {...sliderSettings}>

                    {logos.map((logo) => (
                        <div
                            className={classNames(slide, { comingSoon: logo.soon })}
                            key={logo.file}
                        >
                            <img src={pathPrefix + logo.file} alt={logo.title} />
                            <p>{logo.title}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};
export default LogoShowcase;
